.custom-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  height: 60px;
  padding: 15px;
  background: linear-gradient(to left, #D000EB, black); 
}

.navbar-collapse {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  z-index: 1000;
  background-color: #0f0017f2;
  border-radius: 5px;
  width: 200px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-toggler {
  margin-left: auto;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.custom-toggler-icon {
  color: #fff; 
  font-size: 1.5em; 
  transition: transform 0.3s ease;
}

.navbar-nav {
  margin-top: 10px;
}

.navbar-nav .nav-item {
  margin-bottom: 5px;
}

.navbar-nav .nav-link {
  padding: 10px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  background-color: #af6dd3;
}

@media (min-width: 992px) {
 
  .navbar-collapse {
    position: static;
    top: auto;
    right: auto;
    background-color: transparent;
    box-shadow: none;
    width: auto;
    display: flex;
    justify-content: flex-end;
  }

  .navbar-nav {
    margin-top: 0;
    display: flex;
    flex-direction: row;
  }

  .navbar-nav .nav-item {
    margin-bottom: 0;
  }
}
