body {
    font-family: Arial, sans-serif;
    background-color: #000;
    color: #fff;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
}

h2 {
    color: #800080;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.login-form {
    width: 100%;
}

.input-group {
    margin-bottom: 15px;
    width: 100%;
}

.input-field-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 10px;
    box-sizing: border-box;
}

.input-field-label {
    color: #800080;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}

.input-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #800080;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 4px 10px rgba(128, 0, 128, 0.6);
    box-sizing: border-box;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.input-field:focus {
    outline: none;
    border-color: #800080;
    box-shadow: 0px 4px 10px rgba(128, 0, 128, 0.8);
}

.password-visibility-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #800080;
    cursor: pointer;
}

.error-message {
    color: #ff0000;
    margin-top: 5px;
}

.toggle-container {
    align-items: center;
    margin: 20px 0;
}

.toggle-label {
    color: #666;
    font-size: 14px;
    margin-left: 10px;
}

.button {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    font-size: 16px;
    border-radius: 20px;
    border: none;
    color: #fff;
    cursor: pointer;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(128, 0, 128, 0.4);
    margin-bottom: 10px;
    box-sizing: border-box;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.button.primary {
    background-color: rgba(128, 0, 128, 0.852);
}

.button.secondary {
    background-color: #000;
}

.button.disabled {
    background-color: #444;
    cursor: not-allowed;
}

.link-button {
    background: none;
    border: none;
    color: #800080;
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
}

.continue-guest {
    font-size: 14px;
    color: #800080;
    cursor: pointer;
}

.alert {
    color: #ff0000;
    background-color: #fff;
    border: 1px solid #ff0000;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #800080;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

@media (min-width: 600px) {
    .login-container {
        max-width: 500px;
    }
}

@media (min-width: 900px) {
    .login-container {
        max-width: 600px;
    }
}

@media (min-width: 1200px) {
    .login-container {
        max-width: 700px;
    }
}
