.business-search-view {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.login-button,
.toolbar {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 500px;
  z-index: 2;
  background: linear-gradient(to left, #D000EB, black);
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.login-button {
  display: block;
  background-color: #6a1b9a;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 12px;
}

.login-button:hover {
  background-color: #5a0e8e;
}

.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 2px;
  font-size: 10px;
}

.toolbar-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6a1b9a;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 8px;
  margin: 2px;
}

.toolbar-icon-text {
  display: none;
  margin-left: 4px;
}

@media (min-width: 768px) {
  .toolbar-icon-text {
    display: inline;
  }
}

.search-bar {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 500px;
  z-index: 4;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.search-bar input {
  flex: 1;
  border: none;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  height: 36px;
  margin: 0;
  line-height: 1.2;
}

.search-bar input::placeholder {
  color: #888;
}

.results-overlay {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 500px;
  max-height: 300px;
  background-color: rgba(14, 1, 14, 0.8);
  overflow-y: auto;
  z-index: 4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.result-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px 12px;
  border: 1px solid #6a1b9a;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.result-item:hover {
  background-color: rgba(106, 27, 154, 0.4);
}

.profile-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
}

.result-info {
  flex: 1;
  font-size: 12px;
}

.result-info p {
  margin: 2px 0;
  line-height: 1.4;
  color: white;
}

.result-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  color: white;
}

@media (max-width: 768px) {
  .search-bar {
    top: 70px;
    width: calc(100% - 20px);
    max-width: 90%;
    padding: 0 8px;
  }

  .results-overlay {
    top: 110px;
    width: calc(100% - 20px);
    max-width: 90%;
    max-height: 70vh;
  }

  .map-container {
    height: calc(100% - 60px - 350px);
  }

  .login-button,
  .toolbar {
    padding: 0 6px;
  }

  .login-button {
    font-size: 16px;
    padding: 6px 10px;
  }

  .toolbar {
    font-size: 8px;
    padding: 4px 6px;
  }

  .toolbar-icon {
    font-size: 14px;
  }

  .result-item {
    font-size: 10px;
    padding: 6px 10px;
  }

  .profile-image {
    width: 60px;
    height: 60px;
  }

  .result-info {
    font-size: 10px;
  }

  .result-title {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .search-bar {
    top: 60px;
    width: calc(100% - 10px);
    max-width: 100%;
  }

  .results-overlay {
    top: 100px;
    width: calc(100% - 10px);
    max-width: 100%;
    max-height: 60vh;
  }

  .map-container {
    height: calc(100% - 60px - 320px);
  }

  .login-button,
  .toolbar {
    padding: 0 4px;
  }

  .login-button {
    font-size: 16px;
    padding: 4px 8px;
  }

  .toolbar {
    font-size: 6px;
    padding: 2px 4px;
  }

  .toolbar-icon {
    font-size: 18px;
  }

  .result-item {
    font-size: 8px;
    padding: 4px 8px;
  }

  .profile-image {
    width: 50px;
    height: 50px;
  }

  .result-info {
    font-size: 8px;
  }

  .result-title {
    font-size: 10px;
  }
}
