.business-editor {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  max-width: 600px;
  margin: 0 auto;
}

.header-padding {
  height: 50px;
}

.editor-content {
  overflow-y: auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-height: 0;
  height: 100%;
}

.edit-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.edit-form input[type="text"],
.edit-form input[type="email"] {
  width: 100%;
  max-width: 600px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 12px;
}

.image-placeholder {
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #999;
  text-align: center;
  cursor: pointer;
}

.business-image-placeholder {
  width: 100%;
  max-width: 400px;
  height: 200px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #999;
  text-align: center;
  cursor: pointer;
}

.map-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-icon-preview-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  cursor: pointer;
  border: 2px solid #ccc;
}

.map-icon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.file-input {
  display: none;
}

.image-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.profile-image-container,
.business-image-container {
  width: 100%;
  max-width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.profile-image-container img,
.business-image-container img {
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.profile-image-container img {
  width: 100px;
  height: 100px;
}

.business-image-container img {
  width: 100%;
  border-radius: 10px;
}

.file-input {
  display: none;
}

.toggles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px; 
}

.ios-switch {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.ios-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ios-switch .slider {
  position: relative;
  width: 50px;
  height: 26px;
  background-color: #ccc;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}

.ios-switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.ios-switch input:checked + .slider {
  background-color: #007aff;
}

.ios-switch input:checked + .slider:before {
  transform: translateX(24px);
}

.slider.round {
  border-radius: 34px;
}

button.save-button,
button.cancel-button,
button.edit-button {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

button.save-button {
  background-color: #000000;
  color: white;
}

button.save-button:hover {
  background-color: #333333;
}

button.cancel-button,
button.edit-button {
  background-color: #dc3545;
  color: white;
}

button.cancel-button:hover,
button.edit-button:hover {
  background-color: #c82333;
}

@media (max-width: 600px) {
  .business-editor {
    padding-top: 40px;
  }

  .editor-content {
    padding: 15px;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 12px;
    height: auto;
    max-height: none;
  }

  .edit-form input[type="text"],
  .edit-form input[type="email"] {
    max-width: 100%;
    padding: 10px;
    font-size: 14px;
  }

  .view-data img {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .edit-form input[type="text"],
  .edit-form input[type="email"] {
    font-size: 14px;
    padding: 10px;
  }
}
