.policy-container {
  background-color: #000000;
  color: #ffffff;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

header h1 {
  text-align: center;
  margin-bottom: 30px;
}

.lead {
  text-align: justify;
  font-size: 18px;
}

@media (max-width: 768px) {
  .lead {
      font-size: 16px;
  }
  .policy-container {
      padding-left: 20px;
      padding-right: 20px;
  }
}
