.modal-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  max-width: 600px;
  margin: 0 auto;
}

.header-padding {
  height: 50px;
}

.form-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.modal-content {
  overflow-y: auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-height: 0;
  height: 100%;
}

.event-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-field {
  width: 100%;
  max-width: 600px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 12px;
}

.textarea-field {
  height: 100px;
}

button.submit-button {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #000000;
  color: white;
}

button.submit-button.disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .modal-container {
    padding-top: 40px;
  }

  .modal-content {
    padding: 15px;
    overflow-y: auto;
    background: #f8f8f8;
    border-radius: 8px;
    height: auto;
    max-height: none;
  }

  .input-field {
    max-width: 100%;
    padding: 10px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .input-field {
    font-size: 14px;
    padding: 10px;
  }
}
