.notfound-container {
    text-align: center;
    padding: 50px;
    background-color: #222;
    color: #fff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "M PLUS Rounded 1c", sans-serif;
}

.notfound-container h1 {
    font-size: 48px;
    font-weight: 800;
    color: #ffa100;
}

.notfound-container h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.notfound-container p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px;
}

.home-link {
    text-decoration: none;
    color: #fff;
    background-color: #8700ca;
    padding: 15px 30px;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: 700;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: #000;
}
