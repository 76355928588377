.post-manager {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.icon-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.empty-message {
    text-align: center;
    color: #888;
    font-size: 14px;
}

.tournament-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tournament-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tournament-details {
    cursor: pointer;
    flex: 1;
}

.tournament-details h2 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.tournament-details p {
    font-size: 14px;
    margin: 5px 0;
}

.status {
    font-size: 12px;
    margin-bottom: 5px;
}

.status.active {
    color: green;
}

.status.inactive {
    color: red;
}

.button-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.bookings-info, .delete-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
}

.bookings-info {
    background-color: #000;
}

.delete-button {
    background-color: #dc3545;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    width: 80%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.modal-header h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: linear-gradient(to left, #D000EB, rgb(255, 239, 12));
}

.close-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: #ffffff;
}
