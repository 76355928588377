.marker-info-window-custom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(14, 1, 14, 0.8);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  max-width: 450px;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.marker-info-window-custom__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.marker-info-window-custom__business-details {
  text-align: left;
  margin-bottom: 15px;
  width: 100%;
}

.marker-info-window-custom__business-details p {
  margin: 5px 0;
  font-size: 14px;
  width: 100%;
}

.marker-info-window-custom__business-details h2 {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
}

.marker-info-window-custom__image {
  width: 100%;
  max-height: 200px;
  border-radius: 15px;
  object-fit: cover;
  margin-bottom: 10px;
}

.marker-info-window-custom__close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #6a1b9a;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 14px;
}

.marker-info-window-custom__tournaments-list {
  flex: 1;
  overflow-y: auto;
  margin-top: 15px;
  width: 100%;
}

.marker-info-window-custom__tournament-item {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  text-align: left;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
}

.marker-info-window-custom__tournament-item h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.marker-info-window-custom__tournament-item p {
  margin: 5px 0;
  font-size: 14px;
  text-align: left;
  color: white;
}

.marker-info-window-custom__link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.marker-info-window-custom__link:hover {
  text-decoration: none;
}

@media (max-width: 600px) {
  .marker-info-window-custom {
    padding: 15px;
    max-width: 90%;
    max-height: 90vh;
    box-sizing: border-box;
  }

  .marker-info-window-custom__image {
    max-height: 200px;
  }

  .marker-info-window-custom__business-details h2 {
    font-size: 16px;
  }

  .marker-info-window-custom__business-details p {
    font-size: 12px;
  }

  .marker-info-window-custom__tournament-item {
    padding: 8px;
  }

  .marker-info-window-custom__tournament-item h3 {
    font-size: 14px;
  }

  .marker-info-window-custom__tournament-item p {
    font-size: 12px;
  }
}
