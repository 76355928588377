.custom-settings-view {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  max-width: 600px;
  margin: 0 auto;
}

.custom-header-padding {
  height: 50px;
}

.custom-editor-content {
  overflow-y: auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-height: 0;
  height: 100%;
}

.custom-edit-form input,
.custom-edit-form button {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 16px;
}

.custom-image-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
}

.custom-profile-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  border: 2px solid black;
  cursor: pointer;
  position: relative;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4), 0px 0px 10px rgba(75, 0, 130, 0.3);
}

.custom-profile-image-preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.custom-profile-image-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 50%;
  display: block;
}

.custom-profile-image-preview-container:empty::before {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid black;
  background-color: #f0f0f0;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4), 0px 0px 10px rgba(75, 0, 130, 0.3);
}


.custom-image-label {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}

.custom-label {
  display: block;
  margin-bottom: 12px;
  font-weight: bold;
}

.custom-file-input {
  margin-top: 10px;
  display: none;
}

.custom-save-button,
.custom-cancel-button {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.custom-save-button {
  background-color: #000000;
  color: white;
}

.custom-save-button:hover {
  background-color: #333333;
}

.custom-cancel-button {
  background-color: #dc3545;
  color: white;
}

.custom-cancel-button:hover {
  background-color: #c82333;
}

.custom-reset-password-link {
  text-align: center;
  color: #007aff;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 14px;
}

.custom-reset-password-link:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .custom-settings-view {
    padding-top: 40px;
  }

  .custom-editor-content {
    padding: 15px;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 12px;
    height: auto;
    max-height: none;
  }

  .custom-edit-form input,
  .custom-edit-form button {
    margin-bottom: 8px;
    padding: 10px;
  }

  .custom-profile-image-preview {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .custom-edit-form input,
  .custom-edit-form button {
    font-size: 14px;
    padding: 10px;
  }
}
