@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap');

.home-container {
    background-color: #000000;
    color: #ffffff;
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.m-plus-rounded-1c-bold {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.m-plus-rounded-1c-extrabold {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.main-image-container {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-width: 300px;
    max-width: 600px;
    min-height: 200px;
    max-height: 600px;
}

.image-container img {
    width: 100%;
    height: 100%;
    min-width: 300px;
    max-width: 600px;
    min-height: 200px;
    max-height: 600px;
    object-fit: contain;
    transition: opacity 0.7s ease;
    border-radius: 25px;
}

.image-container img.active {
    opacity: 1;
}

.btn-prev, .btn-next {
    position: absolute;
    background-color: #8700ca;
    color: #fff;
    border: none;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.btn-prev {
    left: 10px;
}

.btn-next {
    right: 10px;
}

.btn-prev:hover,
.btn-next:hover {
    background-color: #000000;
}

.btn-circle.btn-xl {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(to left, #D000EB, black);
    color: #FFFFCC;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    margin-bottom: 10px;
    font-size: 40px;
}

.btn-circle.btn-xl:hover {
    opacity: 0.8;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0);
    transform: rotate(360deg);
    transition: transform 0.5s ease;
}

.app-overview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 50px;
}

.app-feature {
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.app-feature:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.feature-icon {
    font-size: 50px;
    margin-bottom: 10px;
    color: #8700ca;
}

.feature-title {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
}

.feature-description {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.feature-image {
    width: 100%;
    height: auto;
    min-width: 100px;
    max-width: 300px;
    min-height: 200px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
}

.feature-box {
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
}

.feature-box img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .app-overview {
        grid-template-columns: 1fr;
    }
    
    .btn-prev, .btn-next {
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
    
    .btn-prev {
        left: 5px;
    }
    
    .btn-next {
        right: 5px;
    }
}
