.search-view {
    padding: 20px;
    background: #f9f9f9;
  }
  
  .search-view input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .results {
    display: flex;
    flex-direction: column;
  }
  
  .result-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
  }
  
  .profile-info h3 {
    margin: 0 0 10px 0;
  }
  
  .profile-info p {
    margin: 0;
    color: #555;
  }
  